import '../../Static/App.css';
import { getRequest, postRequest } from "../../Services/HttpService"
import { useSelector, useDispatch } from "react-redux";
import { getEnvironment, getWorkingEnvironment, getConfig, setFileName, setCriterias } from "../../Actions/Index"
import { useEffect, useState } from "react";
import operations from '../../Data/Operations.json';
import MultiSelect from "../DevextremeComponents/DevExtremeMultiSelect"
import Grid from "../DevextremeComponents/DevExtremeGrid"
import DxDiagram from "../DevextremeComponents/DevExtremeDiagram"
import RelationshipDiagram from "../DevextremeComponents/RelationshipDiagram"
import OperationManager from "../Managers/OperationManager"
import Enviornment from '../Enviornment Components/Enviornment';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import AppStateContext from '../../ContextApi/AppState';
import React from 'react';
import { Height } from 'devextreme-react/chart';
import CustomModal from '../Utils/CustomModel';
import Swal from 'sweetalert2';


var completeExpression = "";
var expression = "";
var _globalTableName = "";

function Review() {

  const handleClose = () => {
    setSelectedStatement(null)
    setOperation(null)
    sessionStorage.removeItem("data-review-temp-output-object")
  };

  const AllStoreData = useSelector((state) => state);

  const CriteriaTbl = useSelector((state) => state.getCriteria)
  const AllBranches = useSelector((state) => state.getBranches)
  const FileName = useSelector((state) => state.getFileName);
  const Environment = useSelector((state) => state.getEnvironment);
  const WorkingEnvironment = useSelector((state) => state.getWorkingEnvironment);
  const Nodes = useSelector((state) => state.setNodes);
  const statement_stack = useSelector((state) => state.StatementStack)
  const dispatch = useDispatch();

  const [FullExpression, setFullExpression] = useState("");
  const [operation, setOperation] = useState(null);
  const [showGeneratedScript, setShowGeneratedScript] = useState(false);
  const [selectedStatement, setSelectedStatement] = useState(null);

  const [tableData, setTableData] = useState(null);
  const [TableName, setTableName] = useState("");

  const [TemplateSaveEndPointName, setTemplateSaveEndPointName] = useState("");
  const [TempTableListToUpload, setTempTableListToUpload] = useState([]);
  const [TempVersionName, setTempVersionName] = useState("");
  const [TemplateId, setTemplateId] = useState(0);


  const [RelationshipNodes, setRelationshipNodes] = useState([]);
  const [RelationshipEdges, setRelationshipEdges] = useState([]);


  const [showDiagram, setShowDiagram] = useState(true);
  const [showEnvPanel, setShowEnvPanel] = useState(true);

  // const [CriteriaTbl, setCriteriaTbl] = useState(null);
  const [ShowCriteriaEnabler, setShowCriteriaEnabler] = useState(false);
  const [selectedCriteras, setSelectedCriteras] = useState([]);

  const [keyCritsRows, setKeyCritsRows] = useState({});
  const [FieldsKeyCrits, setFieldsKeyCrits] = useState({});



  const getTableAsDataSource = () => {
    let tables = Object.keys(WorkingEnvironment).length ? [...WorkingEnvironment.tables] : [...Environment.tables];
    var source = [];
    for (var i = 0; i < tables.length; i++) {
      source.push({ Key: tables[i].name });
    }
    return source;
  }

  const setStatementAndOperation = (statement) => {
    setSelectedStatement(statement);
    setOperation(statement.operation);
  }
  useEffect(() => {

    var _keyCritsRows = {}
    var _FieldsKeyCrits = {}
    CriteriaTbl.forEach(criteria => {
      if (criteria.enabled) {
        if (criteria.Type === 0)
          criteria.SGKEY.forEach(sgKey => _keyCritsRows[sgKey] = criteria);
        if (criteria.Type === 1) {
          if (typeof criteria.Fields == "string") {
            try
            {
              criteria.Fields = JSON.parse(criteria.Fields)
            }
            catch{
              criteria.Fields = criteria.Fields.split(',').map(x => x.trim())
            }
          }
          criteria.SGKEY.forEach(sgKey => {
            criteria.Fields.forEach(field => {
              if (!_FieldsKeyCrits[field]) {
                _FieldsKeyCrits[field] = {};
              }
              _FieldsKeyCrits[field][sgKey] = criteria
            })
          });
        }
      }
    });

    setKeyCritsRows(_keyCritsRows);
    setFieldsKeyCrits(_FieldsKeyCrits);


  }, [CriteriaTbl])
  const getPropsAsText = (obj) => {
    return Object.entries(obj).map(([key, value]) => `${key}: ${value}`).join(", ");
  }

  const onRowPrepared = (e) => {
    if (e.rowType === "data") {
      if (Object.keys(keyCritsRows).includes(e.data.SGKEY)) {
        var style = JSON.parse(keyCritsRows[e.data.SGKEY].Style);
        for (const key in style) {
          e.rowElement.style[key] = style[key]
        }

      }
    }
  }
  const onCellPrepared = (e) => {
    if (e.rowType === "data") {
      if (Object.keys(FieldsKeyCrits).includes(e.column.dataField)) {
        let FieldCriticObject = FieldsKeyCrits[e.column.dataField][e.data.SGKEY];
        if (FieldCriticObject) {
          // if (FieldsKeyCrits[e.column.dataField][e.data.SGKEY].SGKEY.includes(e.data.SGKEY)) {

            var style = JSON.parse(FieldCriticObject.Style);
            for (const key in style) {
              e.cellElement.style[key] = style[key]  
            }
          // }
        }

      }
    }
  }
  useEffect(() => {
    if (FileName.length && window.isStartingEnvironment) {
      getRequest("/DataReview/Describe?link=" + FileName, "Creating your base environment...").then((result) => {
        dispatch({ ...getEnvironment(), envData: result.data });
      })
    }
    else if (FileName.length && !window.isStartingEnvironment) {
      getRequest("/DataReview/Describe?link=" + FileName, "Creating your working environment...").then((result) => {
        dispatch({ ...getWorkingEnvironment(), envData: result.data });
      })
    }
  }, [FileName])


  useEffect(() => {
    if (Environment.tables && Environment.tables.length && window.isStartingEnvironment) {
      getTable(Environment.tables[0].name)
      
      if (Environment.tables.find(x => x.name == "Criteria")) {
        getCriteriaTable("Criteria")
      }
    }
  }, [Environment])

  useEffect(() => {
    if (WorkingEnvironment.tables && WorkingEnvironment.tables.length) {
      getTable(WorkingEnvironment.tables[0].name)
      
      if (WorkingEnvironment.tables.find(x => x.name == "Criteria")) {
        getCriteriaTable("Criteria")
      }
    }
  }, [WorkingEnvironment])

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch])


  const Interpret = (fileName, Script) => {
    postRequest(`/DataReview/Interpret?link=${fileName}`, Script, "Interpreting script").then((result) => {
      
      let objectId = result.data.result.objId
      let expiresAt = result.data.result.expiresAt


      let FileNames = [...statement_stack.File, objectId];
      dispatch({ type: "SET_STACK_FILENAME", stat: FileNames })


      window.isStartingEnvironment = false;
      dispatch({ ...setFileName(), fileName: objectId })
    }).catch(err => {
      console.log(err)
    })
  }

  const Run = (fileName, Script) => {
    postRequest(`/DataReview/Run?link=${fileName}`, Script, "Running operation script").then((result) => {
      
      let objectId = result.data.result.objId
      let expiresAt = result.data.result.expiresAt

      let FileNames = [...statement_stack.File, objectId];
      dispatch({ type: "SET_STACK_FILENAME", stat: FileNames })

      window.isStartingEnvironment = false;
      dispatch({ ...setFileName(), fileName: objectId })
    }).catch(err => {
      console.log(err)
    })
  }
  const SelectedOperation = (func) => {
    operations.filter(obj => {
      if (obj.Name === func) {
        setOperation(obj);
      }
    });
  }

  const getCriteriaTable = (tableName) => {
    getRequest(`/DataReview/GetTable?name=${tableName}&link=${FileName}`, "Fetching criteria information...")
      .then((result) => {
        var groupedCriterias = result.data.reduce((acc, cur) => {
          const group = acc[cur.Name];
          if (!group) {
            acc[cur.Name] = {
              Name: cur.Name,
              Style: cur.Style,
              Type: cur.Type,
              Fields: cur.Fields,
              SGKEY: [cur.SGKEY],
              enabled: false
            };
          } else {
            group.SGKEY.push(cur.SGKEY);
          }
          return acc;
        }, {});

        groupedCriterias = Object.values(groupedCriterias);





        dispatch({ ...setCriterias(), criteria: groupedCriterias })
        // setCriteriaTbl(groupedCriterias)
      }).catch(e => {
        console.log(e)
      })
  }


  const getTable = (tableName) => {
    _globalTableName = tableName;
    getRequest(`/DataReview/GetTable?name=${tableName}&link=${FileName}`, "Fetching table data...")
      .then((result) => {
        setTableName(tableName);
        setTableData(result.data)
      }).catch(e => {
        console.log(e)
      })
  }

  const initiateBranchScript = () => {
    let branchId = AllBranches.find(x => x.IsSelected).ID;
    completeExpression = "";
    expression = completeExpression;
    generateBranchScript(branchId);
  }

  const generateBranchScript = (branchId) => {
    let currentBranchNodes = [...Nodes.filter(x => x.BranchId == branchId)];
    var childBranches = [];
    for (let node of currentBranchNodes) {
      if (!node.Statement || !node.Statement.script) {
        continue;
      }
      let script = getGeneratedScriptFromNode(node.ID);
      if (expression.length)
        expression += "\n";
      expression += script;

      completeExpression = expression;

      if (node.ChildBranches.length) {

        for (let childBranch of node.ChildBranches) {
          generateBranchScript(childBranch.ID)
        }
      }
    }
    setShowGeneratedScript(true);
    setFullExpression(expression);
    if (branchId == AllBranches.find(x => x.IsSelected)?.ID) {
      postRequest(`/DataReview/Run?link=${FileName}`, expression, "Running complete branch's script")
        .then((result) => {

          var relations = result.data.relations;
          var deserializedRelations = [];
          var createdVars = [], updatedVars = [], accessedVars = [];
          for (const relation of relations) {
            var meta = JSON.parse(relation.meta);
            deserializedRelations.push({
              created: meta.Item1,
              updated: meta.Item2,
              accessed: meta.Item3
            })
            createdVars = [...createdVars, ...meta.Item1];
            updatedVars = [...updatedVars, ...meta.Item2];
            accessedVars = [...accessedVars, ...meta.Item3];
          }




          createdVars = createdVars.filter(onlyUnique);
          updatedVars = updatedVars.filter(onlyUnique);
          accessedVars = accessedVars.filter(onlyUnique);

          var relationShipNodes = [], relationShipEdges = [];

          var nodeIndex = 0;
          for (const createdVar of createdVars) {
            let to = nodeIndex;
            if (!relationShipNodes.find(x => x.text == createdVar)) {
              relationShipNodes.push({ id: nodeIndex, text: createdVar, type: 'rectangle' });
              nodeIndex++;
            }
            else {
              to = relationShipNodes.find(x => x.text == createdVar).id
            }
            var relationRes = deserializedRelations.filter(x => x.created.includes(createdVar));
            if (relationRes.length) {
              for (let _relation of relationRes) {
                for (const accessed of _relation.accessed) {
                  if (accessed != "") {
                    let from = nodeIndex;
                    if (!relationShipNodes.find(x => x.text == accessed)) {
                      relationShipNodes.push({ id: nodeIndex, text: accessed, type: 'rectangle' });
                      nodeIndex++;
                    }
                    else {
                      from = relationShipNodes.find(x => x.text == accessed).id
                    }
                    if (from != to) {
                      relationShipEdges.push({ id: nodeIndex++, text: null, fromId: from, toId: to });
                    }
                  }
                }
              }

            }
          }
          setRelationshipNodes(relationShipNodes);
          setRelationshipEdges(relationShipEdges);

        })
    }
  }

  const generateAndReturnBranchScript = (branchId) => {
    return new Promise((resolve, rejct) => {
      let currentBranchNodes = [...Nodes.filter(x => x.BranchId == branchId)];
      var childBranches = [];
      for (let node of currentBranchNodes) {
        if (!node.Statement || !node.Statement.script) {
          continue;
        }
        let script = getGeneratedScriptFromNode(node.ID);
        if (expression.length)
          expression += "\n";
        expression += script;

        completeExpression = expression;

        if (node.ChildBranches.length) {

          for (let childBranch of node.ChildBranches) {
            generateAndReturnBranchScript(childBranch.ID)
          }
        }
      }
      resolve(expression);
    })
  }

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }
  const getGeneratedScriptFromNode = (nodeId) => {
    let nodes = [...Nodes];
    let node = nodes.find(x => x.ID == nodeId);
    if (node) {
      if (node.Statement) {
        return node.Statement.script;
      }
    }
    return "//could not found script."
  }

  const handleCloseTable = () => {
    setTableData(null)
    setTableName("");
  }
  const handleRelationshipOverviewClose = () => {
    setShowGeneratedScript(false)
    setRelationshipNodes([]);
    setRelationshipEdges([]);
  }

  const saveReview = async () => {
    if (TempVersionName === "" || TempVersionName === null) {
      return;
    }
    var queryStrings = (function (a) {
      if (a == "") return {};
      var b = {};
      for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length == 1)
          b[p[0]] = "";
        else
          b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
      }
      return b;
    })(window.location.search.substr(1).split('&'));
    let template_id;
    if (queryStrings?.template_id) {
      template_id = queryStrings?.template_id;
      if (Number(template_id) > 0) {
        setTemplateId(Number(template_id))
      }
      else {
        template_id = TemplateId;
      }
    }
    let fileKey;
    if (queryStrings?.fileKey) {
      fileKey = queryStrings?.fileKey;
    }
    let studyId;
    if (queryStrings?.studyId) {
      studyId = queryStrings?.studyId;
    }
    const { getConfig, ...clonedStore } = { ...AllStoreData };

    let branchId = AllBranches.find(x => x.IsSelected).ID;
    completeExpression = "";
    expression = completeExpression;
    clonedStore.generatedScript = await generateAndReturnBranchScript(branchId);
    clonedStore.selectedTablesList = TempTableListToUpload;
    let storeData = JSON.stringify(clonedStore);

    postRequest(`/DataReview/${TemplateSaveEndPointName}?template_id=${template_id}&version_name=${TempVersionName}&fileKey=${fileKey}&studyId=${studyId}`, JSON.stringify(storeData), "Saving data").then((result) => {
      if (TemplateSaveEndPointName === "SaveTemplateForReport") {
        setTemplateId(Number(result.data))
      }
      setTempTableListToUpload([]);
      setTempVersionName("");
      setTemplateSaveEndPointName("");
      Swal.fire(
        'Saved!',
        'Template saved.',
        'success'
      )
    }).catch(err => {
      console.log(err)
      setTempTableListToUpload([]);
      setTempVersionName("");
      setTemplateSaveEndPointName("");
      Swal.fire(
        'Error!',
        'Some error occured.',
        'error'
      )
    })
  }
  const getClipboardContent = (e) => {
    e.preventDefault();
    getRequest(`/DataReview/GetClipboardContent?fileKey=${FileName}&table_name=${TableName}&isChart=${(TableName == "Charts")}`, "Fetching content to be copied...")
      .then((result) => {
        var textToBeCopied = typeof result === "string" ? result : result.data;
        navigator.clipboard.write([
          new window.ClipboardItem({
            "text/html": new Blob([textToBeCopied], { type: "text/html" })
          })
        ])
          .then(() => {
            Swal.fire(
              'Copied!',
              'Content copied to clipboard',
              'success'
            )
          })
          .catch((error) => {
            console.error("Error copying text: ", error);
          });
      }).catch(e => {
        console.log(e)
      })
  }

  const getSelectedCrits = (crits) => {
    setSelectedCriteras(crits);
  }

  const copyTableName = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(`[${TableName}]`)
      .then(() => {
        Swal.fire(
          'Copied!',
          'Content copied to clipboard',
          'success'
        )
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  }

  const setColsToUploadTemplate = (cols) => {
    setTempTableListToUpload(cols);
  }
  const closeTemplateSaver = () => {
    setTemplateSaveEndPointName("");
    setTempTableListToUpload([]);
    setTempVersionName("");
  }

  const toggleDiagram = () => {
    setShowDiagram(!showDiagram);
  }
  const toggleEnvData = () => {
    setShowEnvPanel(!showEnvPanel);
  }

  const saveEnabledCrits = () => {
    let critTbl = [...CriteriaTbl]

    critTbl.forEach(c => {
      if (selectedCriteras.includes(c))
        c.enabled = true;
      else
        c.enabled = false;
    });

    dispatch({ ...setCriterias(), criteria: critTbl });
    setShowCriteriaEnabler(false)
  }


  return (
    <>
      <Navbar bg="primary" variant="dark" style={{ padding: "10px 20px" }}>
        <Container fluid>
          <Navbar.Brand >Data Review</Navbar.Brand>
          <Nav className="me-auto">
          </Nav>

          <Nav>
            <Navbar.Text >
              <span style={{ marginRight: "20px" }}>
                <strong>Selected Branch:</strong> {AllBranches.find(x => x.IsSelected).Name}
              </span>
            </Navbar.Text>
            <Nav.Link onClick={() => initiateBranchScript()} >
              Generate Script
            </Nav.Link>
            <Nav.Link onClick={() => setTemplateSaveEndPointName("SaveTemplateForReport")} >
              Save Template For Report
            </Nav.Link>
            <Nav.Link onClick={() => setTemplateSaveEndPointName("SaveReviewAsATemplate")} >
              Save Review As Template
            </Nav.Link>
            <Nav.Link onClick={() => setTemplateSaveEndPointName("SaveReview")} >
              Save Review
            </Nav.Link>

          </Nav>
        </Container>
      </Navbar>

      <Container className='height-100' fluid>
        <Row className='height-100'>
          {showEnvPanel && <Col md="3" className='sidebar-bg'>
            <Enviornment onSelectedOperation={SelectedOperation} onTableClicked={getTable} />
          </Col>}
          <Col md={(showDiagram && showEnvPanel) ? "6" : (!showDiagram && !showEnvPanel) ? "12" : "9"} className='sidebar-bg'>
            <div className='table-header'>
              <h4 className='mb-0'>Selected Table: {TableName}</h4>
              <a href='#' title='Click here to copy content to clipboard.' onClick={getClipboardContent} style={{ marginLeft: "20px" }}>
                <span class="material-icons">
                  content_copy
                </span>
              </a>
              <a href='#' title='Click here to copy table name to clipboard.' onClick={copyTableName} style={{ marginLeft: "20px", fontSize: "24px", marginTop: "-6px" }}>
                {"{..}"}
              </a>
            </div>
            <Grid dataSource={tableData} height = "85vh" showSvg={TableName == "Charts" ? true : false} tableName={TableName} enableExport={true} setCrits={CriteriaTbl ? () => setShowCriteriaEnabler(true) : undefined} onRowPrepared={onRowPrepared} onCellPrepared={onCellPrepared} crits = {CriteriaTbl} keyCritsRows = {keyCritsRows} FieldsKeyCrits = {FieldsKeyCrits} />
          </Col>
          {showDiagram && <Col md="3">
            <DxDiagram nodesData={Nodes} setSelectedStatement={setStatementAndOperation} />
          </Col>}
        </Row>
      </Container>


      <div className='grid-expendable-right' onClick={toggleEnvData}>
        <span class="material-icons">
          {showEnvPanel ? "chevron_left" : "chevron_right"}
        </span>
      </div>
      <div className='grid-expendable-left' onClick={toggleDiagram}>
        <span class="material-icons">
          {showDiagram ? "chevron_right" : "chevron_left"}
        </span>
      </div>


      {/* Generated Script Model */}
      <Modal show={showGeneratedScript} onHide={handleRelationshipOverviewClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Relationship Overview:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='mb-3'>Diagram</h5>
          <RelationshipDiagram nodes={RelationshipNodes} edges={RelationshipEdges} ></RelationshipDiagram>
          <h5 className='mt-3 mb-3'>Generated Script:</h5>
          <textarea rows={7} className="form-control">{FullExpression}</textarea>
        </Modal.Body>
      </Modal>


      <CustomModal show={ShowCriteriaEnabler} onClose={() => setShowCriteriaEnabler(false)} title="Enable / Disable Criteria">
        <div style={{ minHeight: "200px" }}>
          <Grid dataSource={CriteriaTbl} columns={["Name", "Type", "Fields"]} showSvg={false} tableName={TableName} enableExport={false} selectionMode="multiple" onSelection={getSelectedCrits} />

          <button onClick={() => saveEnabledCrits()} className='btn btn-primary'>Save</button>
        </div>
      </CustomModal>
      <CustomModal show={(TemplateSaveEndPointName != "" && TemplateSaveEndPointName != null)} onClose={closeTemplateSaver} title="Save template.">
        <div style={{ minHeight: "200px" }}>
          {TemplateSaveEndPointName === "SaveTemplateForReport" && <div>
            <label htmlFor="table-selection" className="form-label">Select tables to upload.</label>
            {(TemplateSaveEndPointName != "" && TemplateSaveEndPointName != null) && <MultiSelect valueExpr="Key" displayExpr="Key" column={["Key"]} dataSource={getTableAsDataSource()} allowMultipleSelection={true} selectedRowKeys={TempTableListToUpload} setSelectedRowKeys={setColsToUploadTemplate} ></MultiSelect>}
          </div>}
          <div className="form-group mt-3">
            <label htmlFor="versionName" className="form-label">Enter version name here</label>
            <input id='versionName' type="text" className="form-control" value={TempVersionName} onChange={e => setTempVersionName(e.target.value)}
              placeholder="Enter version name here." />
          </div>
          <div className='container'>
            <div className='row mt-3'>
              <button onClick={() => saveReview()} className='btn btn-primary'>Save</button>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal show={operation !== null} onClose={handleClose} title={!selectedStatement ? `Create Statement (${operation?.Name})` : `Edit Statement (${operation?.Name})`}>
        {operation && <OperationManager Operation={operation} handleClose={handleClose} selectedStatement={selectedStatement} Interpret={Interpret} Run={Run}></OperationManager>}
      </CustomModal>
      {/* {operation && <OperationManager Operation={operation} handleClose={handleClose} selectedStatement={selectedStatement} Interpret={Interpret} Run={Run}></OperationManager>} */}

    </>
  );
}

export default Review;
