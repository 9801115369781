import getEnvironment from "./GetEnvironment";
import getWorkingEnvironment from "./GetWorkingEnvironment"
import getConfig from "./GetConfig";
import setStatement from "./GetStatements"
import getBranches from "./GetBranches";
import setNodes from "./GetNodes";
import setGraphData from "./GetGraphObjects"
import StatementStack from "./StatementsStack";
import getFileName from "./GetFileName"
import RedoStack from "./RedoStack";
import getCriteria from "./GetCriteria";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
    getEnvironment: getEnvironment,
    getConfig: getConfig,
    setStatement: setStatement,
    setNodes: setNodes,
    getBranches : getBranches,
    StatementStack: StatementStack,
    getFileName: getFileName,
    getWorkingEnvironment: getWorkingEnvironment,
    RedoStack : RedoStack,
    setGraphData: setGraphData,
    getCriteria : getCriteria
})

export default rootReducer;