export const getConfig = () => {
    return {
        type: "GET_CONFIG"
    }
}

export const getEnvironment = () => {
    return {
        type: "GET_ENVIRONMENT"
    }
}
export const getWorkingEnvironment = () => {
    return {
        type: "GET_WORKING_ENVIRONMENT"
    }
}


export const setStatement = () => {
    return {
        type: "SET_STATEMENTS"
    }
}

export const setNodes = () => {
    return {
        type: "SET_NODES"
    }
}

export const setBranches = () => {
    return {
        type: "SET_BRANCHES"
    }
}


export const setFileName = () => {
    return {
        type: "SET_FILENAME"
    }
}



export const setGraphObjects = () => {
    return {
        type: "SET_GRAPH_OBJECTS"
    }
}

export const setCriterias = () => {
    return {
        type: "SET_CRITERIA"
    }
}