import React, { useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/data/odata/store';
import Button from 'devextreme-react/button';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
// import { Workbook } from 'exceljs/dist/exceljs.min.js';
import DataGrid, { FilterRow, FilterPanel,  FilterBuilderPopup, GroupPanel, Scrolling, Column, Export, Toolbar, Item, Selection, Pager, Paging } from 'devextreme-react/data-grid';


const allowedPageSizes = [100, 200, 'all'];

const createGuid = () => {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4()).toLowerCase();
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
};
function gridCellData(gridData) {
    return gridData.data[gridData.column.caption];
}
const svgTemplate = (template) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: gridCellData(template) }} />
    )
}


function colorToHex(color) {
    let d = document.createElement("div");
    d.style.color = color;
    document.body.appendChild(d);
    //Color in RGB
    let RGBcolor = window.getComputedStyle(d).color;

    RGBcolor = splitRgbValues(RGBcolor);
    let hex = rgbToHex(RGBcolor[0], RGBcolor[1], RGBcolor[2]);
    colorHex[color] = hex;
    d.remove();
    return hex;
}
function rgbToHex(r, g, b) {
    return (
        ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)
    );
}
function splitRgbValues(stringRgb) {
    return stringRgb
        .substring(4, stringRgb.length - 1)
        .replace(/ /g, "")
        .split(",");
}

const colorHex = {};

const Grid = ({ key, columns, dataSource, showSvg,height, tableName, enableExport, setCrits, onSelection, crits, keyCritsRows, FieldsKeyCrits, selectionMode = "none", onRowPrepared, onCellPrepared }) => {
    const dataGrid = React.createRef();
    const [uniqueId, setUniqueId] = useState(createGuid());
    const [ScrollingEnabled, setScrollingEnabled] = useState(false);
    const [displayMode, setDisplayMode] = useState('full');

    const onExporting = (e) => {
        
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function ({ gridCell, excelCell }) {
                let _crits = crits;
                //we are going to check if the incoming data is a row type critic. becuase we dont get the style properties for row type. thats why we are comparing it from our main critics object.
                if (gridCell.rowType === 'data') {
                    const sgKey = gridCell.data.SGKEY;
                    const rowType = keyCritsRows[sgKey]  // rowTypeCrits.find(x => x.sgKey.includes(sgKey));
                    if (!FieldsKeyCrits[gridCell.column.dataField])
                        FieldsKeyCrits[gridCell.column.dataField] = {};
                    const cellType = FieldsKeyCrits[gridCell.column.dataField][sgKey];



                    if (cellType) {
                        let style = JSON.parse(cellType.Style);
                        let color = colorHex[style['background-color']] ?? colorToHex(style['background-color']);
                        excelCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: color },
                        };
                        excelCell.font = {
                            color: { argb: colorHex[style['color']] ?? colorToHex(style['color']) },
                        };
                    }
                    else if (rowType) {
                        let style = JSON.parse(rowType.Style);
                        excelCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: colorHex[style['background-color']] ?? colorToHex(style['background-color']) },
                        };
                        excelCell.font = {
                            color: { argb: colorHex[style['color']] ?? colorToHex(style['color']) },
                        };
                    }
                }
            }
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                });
        });
        e.cancel = true;
    }
    const onSelectionChanged = (e) => {
        onSelection(e.selectedRowsData)

    }
    const onContentReady = (e) => {
        e.component.columnOption("SGKEY", "visible", false);
    }
    const toggleScrolling = (e) => {
        setScrollingEnabled(!ScrollingEnabled);
    }
    return (

        <DataGrid
            id={uniqueId}
            ref={dataGrid}
            columnChooser={{
                enabled: true,
                mode: "dragAndDrop"
            }}
            filterPanel={{ visible: true }}
            rowAlternationEnabled={false}
            allowColumnResizing={true}
            allowColumnReordering={true}
            allowSelection={true}
            columnAutoWidth={true}
            dataSource={dataSource}
            defaultColumns={columns}
            showBorders={true}
            onRowPrepared={onRowPrepared}
            onCellPrepared={onCellPrepared}
            onSelectionChanged={onSelectionChanged}
            onExporting={onExporting}
            onContentReady={onContentReady}
            height={height}
        >
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />
            <GroupPanel visible={true} />
            {ScrollingEnabled && <Scrolling mode="virtual" />}

            <Paging defaultPageSize={100} />
            <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode={displayMode}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
            <Export enabled={enableExport} fileName={tableName ?? "Data"} />
            {showSvg && <Column caption="Name" cellRender={svgTemplate} />}
            {showSvg && <Column caption="SVG" cellRender={svgTemplate} />}
            <Selection
                mode={selectionMode}
                selectAllMode='allPages'
                showCheckBoxesMode='always'
            />
            <Toolbar>
                <Item location="before">
                    <div className="dx-item dx-toolbar-item dx-toolbar-button">
                        <div className="dx-item-content dx-toolbar-item-content">
                            <div className="dx-datagrid-group-panel">
                                <div className="dx-group-panel-message">Drag a column header here to group by that column</div>
                            </div>
                        </div>
                    </div>
                </Item>
                {/* <Item location="before">
                    <Button
                        text= {ScrollingEnabled ? "Disable Scrolling" :"Enable Scrolling"}
                        type="normal"
                        stylingMode="contained"                        
                        onClick={toggleScrolling}
                    />
                </Item> */}
                <Item location="after">
                    <Button
                        text="Enable / Disable Criteria"
                        type="normal"
                        stylingMode="contained"
                        disabled={setCrits ? false : true}
                        onClick={setCrits}
                    />
                </Item>
                <Item
                    name="exportButton"
                />
                <Item
                    name="columnChooserButton"
                />
                <Item
                    name="searchPanel"
                />
            </Toolbar>
        </DataGrid>
    );
}

export default Grid;