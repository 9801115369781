import React, { useState, useEffect } from 'react';
import reactCSS from 'reactcss'

import rgbHex from "rgb-hex";
import { SketchPicker } from 'react-color'




const ColorPicker = ({ defaultColor,  onChangeComplete }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      });
      const styles = reactCSS({
        'default': {
          color: {
            width: '100%',
            height: '30px',
            borderRadius: '2px',
            background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
          },
          swatch: {
            width: "100%",
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });

      useEffect(() => {
        setColor(defaultColor)
      }, [defaultColor])

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    const handleChange = (c, event) => {
        var hex = rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a);

        setColor(c.rgb);
        onChangeComplete(c.rgb);
    };
    return (
        <>
            <div>
                <div style={styles.swatch} onClick={handleClick}>
                    <div style={styles.color} />
                </div>
                {displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color="#000" onChangeComplete={handleChange} />
                </div> : null}

            </div>
        </>
    );
}
export default ColorPicker;