import React, { useState, useEffect } from 'react';
import ColorPicker from "../Utils/ColorPicker"



const CssColorPicker = ({ param, updateOutput, selectedStatement }) => {
    const [initValue, setInitValue] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      });
    useEffect(() => {
        if (selectedStatement != null && selectedStatement.output[param?.Name]) {   
            let prevColor = selectedStatement.output[param?.Name];  
            //as color is stored in the form of string in the output object.. but here we are using rgba object       
            updateOutput(param.Name, prevColor);
            prevColor = prevColor.replace(/ /g,''); //removing extra spaces if there are any
            let rgba = prevColor.split("rgba(")[1].split(")")[0].split(",")
            let color = {
                r : rgba[0],
                g : rgba[1],
                b : rgba[2],
                a : rgba[3],
            }

            setInitValue(color);
        }
        else{            
            updateOutput(param.Name, `rgba(${ initValue.r }, ${ initValue.g }, ${ initValue.b }, ${ initValue.a })`)
        }

    }, [selectedStatement]);



    const setData = (color) => {
        
        updateOutput(param.Name, `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`)
        setInitValue(color)
    }


    return (
        <>
            <div id="tbl-selection-Add" className="form-group" style={{ "display": param.Visible ? "" : "none" }}>
                <label className="form-label">{param.Name} :</label>
                <ColorPicker defaultColor = {initValue}  onChangeComplete = {setData}/>
            </div>
        </>
    )
}

export default CssColorPicker;