import React, { useState, useEffect, useRef } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';



const SimpleDropdown = ({ dataSource, selectedItem, setData }) => {
    const [value, setValue] = useState(selectedItem);
    

    useEffect(() => {
        console.log("dataSource: ", dataSource);
    }, [dataSource])


    useEffect(() => {
        setData(value);
    }, [value])

    const onValueChanged = (e) => {
        setValue(e.value);
    }


    return (
        <>
            <SelectBox items={dataSource}
                defaultValue={selectedItem}
                value={selectedItem}
                searchEnabled={true}
                searchMode="contains"
                searchTimeout={200}
                minSearchLength={0}
                showDataBeforeSearch={false}
                onValueChanged={onValueChanged}
            />
        </>
    )
}

export default SimpleDropdown;