import React, { useState, useEffect, useRef } from 'react';
import { SelectBox } from 'devextreme-react/select-box';



const FormatingType = ({ param, items, updateOutput, setSelectedTblCols, selectedStatement, remvoeProp }) => {
    const [initValue, setInitValue] = useState(null);
    useEffect(() => {
        if (items.length) {
            
            if (selectedStatement != null && selectedStatement.output[param?.Name]) {
                let value = items.find(x => x.expressionValue == selectedStatement.output[param?.Name])
                if(value)
                {
                    updateOutput(param.Name, value.expressionValue)
                    setInitValue(value.expressionValue)
                }
                else{
                    updateOutput(param.Name, items[0].expressionValue)
                    setInitValue(items[0].expressionValue)
                }
            }
            else {
                updateOutput(param.Name, items[0].expressionValue)
                setInitValue(items[0].expressionValue)
            }
        }

    }, [items])

    useEffect(() => {
        if(param.ChildParamNValue && param.ChildParamName && param.ChildParamNValue != initValue)
            updateOutput(param.ChildParamName, [])
    }, [initValue])


    const setData = (value) => {
        updateOutput(param.Name, value)
        setInitValue(value)
    }

    const onValueChanged = (e) => {
        setData(e.value);
    }


    return (
        <>
            <div id="tbl-selection-Add" className="form-group" style={{ "display": param.Visible ? "" : "none" }}>
                <label htmlFor="SelectTable" className="form-label">{param.Name} :</label>

                <SelectBox items={items}
                
                    displayExpr="displayValue"
                    valueExpr="expressionValue"
                    defaultValue={initValue}
                    value={initValue}
                    searchEnabled={true}
                    searchMode="contains"
                    searchTimeout={200}
                    minSearchLength={0}
                    showDataBeforeSearch={false}
                    onValueChanged={onValueChanged}
                />
            </div>
        </>
    )
}

export default FormatingType;